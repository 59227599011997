.app-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.cookie-banner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 9999;
  backdrop-filter: blur(8px); /* Add blur effect */
}
